import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import OfflineBoundary from "./OfflineBoundary";

// Register the service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OfflineBoundary>
      <App />
    </OfflineBoundary>
  </React.StrictMode>
);
